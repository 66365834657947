<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        If a solution of a monoprotic acid is prepared to a concentration of
        <number-value :value="concentration" unit="\text{M}" />
        and the solution has a
        <stemble-latex content="$\text{pH}$" />
        of
        <number-value :value="phValue" />
        , what is the
        <stemble-latex content="$\text{K}_\text{a}$" />
        value for the acid?
      </p>

      <calculation-input
        v-model="inputs.Ka"
        prepend-text="$\text{K}_\text{a}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question141',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ka: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
    phValue() {
      return this.taskState.getValueBySymbol('phValue').content;
    },
  },
};
</script>
